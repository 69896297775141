@media only screen and (min-width: 360px) and (max-width: 414px) {
  .belowLanding {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    font-family: "Poppins", sans-serif;
    z-index: 15;
  }

  /*left side small-sized*/
  .contactLeftSide {
    /* background-color: red; */
    position: relative;
    max-width: 75%;
    min-width: 75%;
    width: 75%;
    height: 100%;
    box-sizing: border-box;
  }

  /*santi image*/
  .santiImage {
    position: absolute;
    max-width: 80%;
    max-height: 50%;
    margin-left: 5%;
    /* margin-top: 5%; */
    padding: 3%;
    padding-bottom: 0px;
    border-radius: 25px;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(146, 146, 146);
  }

  /*phone demo small-sized*/
  .mockPhoneCasing {
    display: none;
  }

  .mockPhoneNotif {
    display: none;
  }

  .mockPhoneSkladniki {
    display: none;
  }

  /*background blob small-sized*/
  .redAndOrangeHue {
    position: relative;
    width: 90%;
    height: 60%;
    margin-left: 30%;
    margin-top: 5.5%;
  }

  .raoHueItself {
    max-width: 100%;
    max-height: 100%;
  }
  .raoHueItself:nth-of-type(2) {
    filter: blur(10px);
    transform: scale(1.1);
    margin-top: -93%;
  }

  .secondBlobDiv {
    position: absolute;
    width: 80%;
    height: 50%;
    /* margin-left: 10%; */
    margin-top: 65.5%;
    margin-bottom: 0;
  }

  .secondBlob {
    transform: rotateZ(-120deg);
    max-width: 100%;
    max-height: 100%;
    margin-top: -25%;
  }
  .secondBlob:nth-of-type(2) {
    filter: blur(10px);
    transform: rotateZ(-150deg) scale(1.1);
    margin-top: -105%;
  }

  /*dot mesh and the plane png*/
  .dotMeshDiv {
    position: absolute;
    width: 30%;
    height: 30%;
    margin-left: 45.5%;
    margin-top: 15.5%;
  }

  .dotMesh {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    filter: invert(1);
  }

  .paperPlaneDiv {
    position: absolute;
    width: 30%;
    height: 3%;
    margin-left: 70%;
    margin-top: 20%;
    animation: hoverPlane 10s linear infinite;
  }

  .paperPlane {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    /* filter: invert(1); */
    transform: rotateZ(-15deg) scale(1);
  }

  @keyframes hoverPlane {
    0%,
    100% {
      margin-top: 20%;
    }
    50% {
      margin-top: 18.5%;
    }
  }
  /*right side small sized*/
  /*right side*/
  .contactRightSide {
    position: absolute;
    max-width: 85%;
    width: 85%;
    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 25px;
    margin-top: 40%;
    margin-left: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formBody {
    width: 80%;
    height: 472px;
    margin-top: 5%;
    background-color: rgb(248, 248, 248);
    border-radius: 25px;
    filter: drop-shadow(0px 15px 16px rgba(0, 0, 0, 0.1));
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .leftFormBody {
    max-width: 100%;
    width: 90%;
    height: 100%;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }

  .formItselfForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .formItselfFormWithin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .rightFormBody {
    width: 0%;
  }

  .infoCard {
    display: none;
  }

  .infoCardDeets {
    display: none;
  }

  .githubLogoICD {
    display: none;
  }

  .infoCardTitle {
    display: none;
  }

  .blueAndGreyContainer {
    position: absolute;
    width: 60%;
    max-height: 30%;
    margin-left: 35%;
    margin-top: 40%;
    transform: rotateZ(225deg);
    /* background-color: red; */
  }

  .gabHue {
    max-width: 100%;
    filter: blur(100px);
    opacity: 0.3;
  }
  .gabHue:nth-of-type(2) {
    margin-top: -100%;
    filter: blur(100px);
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .belowLanding {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    font-family: "Poppins", sans-serif;
    z-index: 15;
  }

  /*left side mid-sized*/
  .contactLeftSide {
    /* background-color: red; */
    position: relative;
    max-width: 50%;
    min-width: 50%;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
  }

  /*santi image*/
  .santiImage {
    position: absolute;
    max-width: 80%;
    max-height: 50%;
    margin-left: 5%;
    /* margin-top: 5%; */
    padding: 3%;
    padding-bottom: 0px;
    border-radius: 25px;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(146, 146, 146);
  }

  /*phone demo*/
  .mockPhoneCasing {
    position: absolute;
    max-width: 50%;
    width: 50%;
    max-height: 40%;
    border-radius: 25px;
    background-color: white;
    margin-top: 40%;
    margin-left: 53%;
    border: 1px solid rgba(146, 146, 146, 0.2);
    filter: drop-shadow(0px 15px 16px rgba(0, 0, 0, 0.1));

    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-y: hidden; */
  }

  .mockPhoneNotif {
    max-width: 100%;
    padding-top: 10%;
    padding-bottom: 0;
  }

  .mockPhoneSkladniki {
    margin-top: 5%;
    box-sizing: content-box;
    overflow-x: hidden;
    max-width: 90%;
  }

  /*background blob*/
  .redAndOrangeHue {
    position: relative;
    width: 60%;
    height: 60%;
    margin-left: 37.5%;
    margin-top: 5.5%;
  }

  .raoHueItself {
    max-width: 100%;
    max-height: 100%;
  }
  .raoHueItself:nth-of-type(2) {
    filter: blur(10px);
    transform: scale(1.1);
    margin-top: -93%;
  }

  .secondBlobDiv {
    position: absolute;
    width: 50%;
    height: 50%;
    /* margin-left: 10%; */
    margin-top: 65.5%;
    margin-bottom: 0;
  }

  .secondBlob {
    transform: rotateZ(-150deg);
    max-width: 100%;
    max-height: 100%;
  }
  .secondBlob:nth-of-type(2) {
    filter: blur(10px);
    transform: rotateZ(-150deg) scale(1.1);
    margin-top: -105%;
  }

  /*dot mesh and the plane png*/
  .dotMeshDiv {
    position: absolute;
    width: 30%;
    height: 30%;
    margin-left: 45.5%;
    margin-top: 15.5%;
  }

  .dotMesh {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    filter: invert(1);
  }

  .paperPlaneDiv {
    position: absolute;
    width: 30%;
    height: 3%;
    margin-left: 70%;
    margin-top: 20%;
    animation: hoverPlane 10s linear infinite;
  }

  .paperPlane {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    /* filter: invert(1); */
    transform: rotateZ(-15deg) scale(1);
  }

  @keyframes hoverPlane {
    0%,
    100% {
      margin-top: 20%;
    }
    50% {
      margin-top: 18.5%;
    }
  }

  /*right side mid-sized*/
  /*right side*/
  .contactRightSide {
    max-width: 50%;
    width: 50%;

    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formBody {
    width: 80%;
    height: 472px;
    margin-top: 5%;
    background-color: rgb(248, 248, 248);
    border-radius: 25px;
    filter: drop-shadow(0px 15px 16px rgba(0, 0, 0, 0.1));
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .leftFormBody {
    max-width: 100%;
    width: 90%;
    height: 100%;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }

  .formItselfForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .formItselfFormWithin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .rightFormBody {
    width: 0%;
  }

  .infoCard {
    display: none;
  }

  .infoCardDeets {
    display: none;
  }

  .githubLogoICD {
    display: none;
  }

  .infoCardTitle {
    display: none;
  }

  .blueAndGreyContainer {
    position: absolute;
    width: 60%;
    max-height: 30%;
    margin-left: 35%;
    margin-top: 40%;
    transform: rotateZ(225deg);
    /* background-color: red; */
  }

  .gabHue {
    max-width: 100%;
    filter: blur(100px);
    opacity: 0.3;
  }
  .gabHue:nth-of-type(2) {
    margin-top: -100%;
    filter: blur(100px);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1920px) {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

  .belowLanding {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    font-family: "Poppins", sans-serif;
    z-index: 15;
  }

  /*left side*/
  .contactLeftSide {
    /* background-color: red; */
    position: relative;
    max-width: 50%;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
  }

  /*santi image*/
  .santiImage {
    position: absolute;
    max-width: 40%;
    margin-left: 20%;
    margin-top: 5%;
    padding: 3%;
    padding-bottom: 0px;
    border-radius: 25px;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(146, 146, 146);
  }

  /*phone demo*/
  .mockPhoneCasing {
    position: absolute;
    max-width: 40%;
    border-radius: 25px;
    background-color: white;
    width: 30%;
    height: 60%;
    margin-top: 20%;
    margin-left: 53%;
    border: 1px solid rgba(146, 146, 146, 0.2);
    filter: drop-shadow(0px 15px 16px rgba(0, 0, 0, 0.1));

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
  }

  .mockPhoneNotif {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 0;
  }

  .mockPhoneSkladniki {
    margin-top: 5%;
    box-sizing: content-box;
    overflow-x: hidden;
    max-width: 90%;
  }

  /*background blob*/
  .redAndOrangeHue {
    position: absolute;
    width: 40%;
    height: 40%;
    margin-left: 37.5%;
    margin-top: -5.5%;
  }

  .raoHueItself {
    max-width: 100%;
    max-height: 100%;
  }
  .raoHueItself:nth-of-type(2) {
    filter: blur(10px);
    transform: scale(1.1);
    margin-top: -70%;
  }

  .secondBlobDiv {
    position: absolute;
    width: 50%;
    height: 50%;
    margin-left: 10%;
    margin-top: 20.5%;
    margin-bottom: 0;
  }

  .secondBlob {
    transform: rotateZ(-150deg);
    max-width: 100%;
    max-height: 100%;
  }
  .secondBlob:nth-of-type(2) {
    filter: blur(10px);
    transform: rotateZ(-150deg) scale(1.1);
    margin-top: -70%;
  }

  .dotMeshDiv {
    position: relative;
    width: 30%;
    height: 30%;
    margin-left: 45.5%;
    margin-top: 35.5%;
  }

  .dotMesh {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    filter: invert(1);
  }

  .paperPlaneDiv {
    position: absolute;
    width: 30%;
    height: 30%;
    margin-left: 55%;
    margin-top: 0;
    animation: hoverPlane 10s linear infinite;
  }

  .paperPlane {
    position: absolute;
    max-width: 80%;
    max-height: 100%;
    /* filter: invert(1); */
    transform: rotateZ(-15deg) scale(0.2);
  }

  @keyframes hoverPlane {
    0%,
    100% {
      margin-top: 0;
    }
    50% {
      margin-top: -1.5%;
    }
  }

  /*right side*/
  .contactRightSide {
    max-width: 50%;
    width: 50%;

    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 25px;
  }

  .formBody {
    width: 80%;
    height: 472px;
    margin-top: 5%;
    background-color: rgb(248, 248, 248);
    border-radius: 25px;
    filter: drop-shadow(0px 15px 16px rgba(0, 0, 0, 0.1));
    /* background-color: blue; */
  }

  .leftFormBody {
    max-width: 50%;
    width: 50%;
    height: 100%;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }
  .rightFormBody {
    max-width: 50%;
    width: 50%;
    height: 100%;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }

  .formItselfForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
  .formItselfFormWithin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .infoCard {
    height: 80%;
    max-width: 90%;
    width: 90%;
    align-self: center;
    margin-top: 1rem;
    border-radius: 25px;
    filter: drop-shadow(0px 15px 10px rgba(70, 49, 119, 0.1));
  }

  .infoCardDeets {
    /* max-width: 80%; */
    transform: skew(15deg, 0) rotateZ(-22.5deg);
    perspective: 23rem;
    perspective-origin: bottom left;
    filter: drop-shadow(-45px 25px 30px rgba(79, 76, 83, 0.3));
    margin-top: 10%;
    margin-left: 10%;
    /* background-color: red; */
  }

  .githubLogoICD {
    max-height: 1.5rem;
  }

  .blueAndGreyContainer {
    position: absolute;
    width: 30%;
    max-height: 30%;
    margin-left: 60%;
    margin-top: 17%;
    transform: rotateZ(225deg);
    /* background-color: red; */
  }

  .gabHue {
    max-width: 100%;
    filter: blur(100px);
  }
  .gabHue:nth-of-type(2) {
    margin-top: -100%;
    filter: blur(100px);
  }
}
